<template>
  <v-container class="max-container referral-container">
    <v-card v-if="sub && sub.status!='trial_1'" class="pa-3 card-box" flat dark color="black">
      <div class="desc-space" />
      <div class="desc-title-box"><h2> Invite friends to  <br> One Channel and earn</h2></div>
      <div class="center-box"><img src="@/assets/referral.png" alt=""></div>
      <v-list two-line class="reffer-rule-box">
        <v-list-item
          v-for="file in files"
          :key="file.title"
        >
          <v-list-item-avatar>
            <img
              width="40"
              src="@/assets/coin.png"
              alt="coin"
            >
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-wrap" v-html="file.title" />
          </v-list-item-content>
        </v-list-item>
        <div class="footer-desc">For trial users, 1 point could be redeemed for one-day free trial.</div>
      </v-list>
      <div class="desc-space" />
      <div>
        <v-row>
          <v-col
            cols="6"
          >
            <v-card
              class="pa-2 box-points"
              outlined
              tile
              @click="toHistory"
            >
              <div class="referral-title">Friends Referred</div>
              <div>
                <div class="point-value referral-title">{{ inviteInfo.invite_user_num }}</div>
                <div><img align="center" src="@/assets/right.png" alt=""></div>
              </div>
            </v-card>
          </v-col>
          <v-col
            cols="6"
          >
            <v-card
              class="pa-2 box-points"
              outlined
              tile
              @click="toRedeem"
            >
              <div class="referral-title">Points Balance</div>
              <div>
                <div class="point-value referral-title">{{ inviteInfo.total_score }}</div>
                <div><img align="center" src="@/assets/right.png" alt=""></div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div class="referral-code-box">
          <div>Referral Code</div>
          <div @click="copyReferralCode"> {{ referralCode }}  <v-icon small>mdi-content-copy</v-icon></div>
        </div>
        <v-list class="copy-link-list">
          <v-list-item @click="copyLink">
            <v-list-item-content>
              <v-list-item-title v-text="'Copy link'" />
            </v-list-item-content>
            <v-list-item-action>
              <img align="center" class="copy-link" src="@/assets/link.png" alt="">
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
      <div class="buttong-justify-center">
        <v-btn large depressed class="tile text-capitalize sub-btn" @click="shareClick()">Share Your Invite</v-btn>
      </div>
    </v-card>
    <v-card v-else class="pa-3 card-box free-trial-card" flat dark color="black">
      <div class="desc-space" />
      <div class="desc-title-box"><h2>Invite to Earn More Free Trial</h2></div>
      <div class="center-box"><img src="@/assets/referral.png" alt=""></div>
      <v-list two-line class="reffer-rule-box">
        <v-list-item
          v-for="file in freeTrialItems"
          :key="file.title"
        >
          <v-list-item-avatar>
            <img
              width="40"
              src="@/assets/coin.png"
              alt="coin"
            >
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-wrap" v-html="file.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="desc-space" />
      <div>
        <v-row v-if="sub">
          <v-col
            cols="6"
          >
            <v-card
              class="pa-2 box-points"
              outlined
              tile
              @click="toHistory"
            >
              <div class="referral-title">Friends Referred</div>
              <div>
                <div class="point-value referral-title">{{ inviteInfo.invite_user_num }}</div>
                <div><img align="center" src="@/assets/right.png" alt=""></div>
              </div>
            </v-card>
          </v-col>
          <v-col
            cols="6"
          >
            <v-card
              class="pa-2 box-points"
              outlined
              tile
              @click="toRedeem"
            >
              <div class="referral-title">Points Balance</div>
              <div>
                <div class="point-value referral-title">{{ inviteInfo.total_score }}</div>
                <div><img align="center" src="@/assets/right.png" alt=""></div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-list class="copy-link-list">
          <v-list-item @click="copyLink">
            <v-list-item-content>
              <v-list-item-title v-text="'Copy link'" />
            </v-list-item-content>
            <v-list-item-action>
              <img align="center" class="copy-link" src="@/assets/link.png" alt="">
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
      <div class="buttong-justify-center">
        <v-btn v-if="!showNext" large depressed class="tile text-capitalize sub-btn" @click="shareClick()">{{ fromType == "register" ? "Share to Continue": "Share Your Invite" }}</v-btn>
        <v-btn v-else large depressed class="tile text-capitalize sub-btn" @click="next()">Continue</v-btn>
      </div>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      class="snackbar-copy"
      color="primary"
    >
      Copied to clipboard
    </v-snackbar>
  </v-container>
</template>

<script>
import { getInviteCount } from '@/api/user'

export default {
  name: 'Referral',
  components: {},
  data() {
    return {
      showNext: false,
      timeout: 1000,
      snackbar: false,
      inviteInfo: {},
      user: {},
      files: [
        {
          title: 'Earn <span class="weight-text">1 point</span> for every friend to register One Channel.'
        },
        {
          title: 'Earn <span class="weight-text">10 points</span> when your friend starts paying for One Channel.'
        },
        {
          title: '<span class="weight-text">1 point</span> is equivalent to <span class="weight-text">1 USD</span>. Points could be used to pay for bills or be redeemed for cash.'
        }
      ],
      freeTrialItems: [
        {
          title: 'Get <span class="weight-text">1 Day</span>  free trial for every new invite.'
        },
        {
          title: 'Get <span class="weight-text">10 Days</span> free trial for every new customer.'
        },
        {
          title: 'Free trials will be converted to credits if you stay as a paying customer.'
        }
      ]
    }
  },
  computed: {
    fromType() {
      const fromType = this.$route.query.ft
      return fromType
    },
    referralCode() {
      return this.$store.state.user.userInfo.user.referal_code
    }

  },
  created() {
    this.user = this.$store.state.user.userInfo.user
    this.sub = this.$store.state.user.userInfo.subscription
    this.getInviteCount()
  },
  methods: {
    copyReferralCode() {
      this.copyValue(this.referralCode)
    },
    getInviteCount() {
      getInviteCount().then(res => {
        this.inviteInfo = res.data
      })
    },
    copyLink() {
      this.copyValue(this.inviteInfo.share.share_link_url)
    },
    copyValue(value) {
      if (!navigator.clipboard) {
        console.log('not supported')
        return
      }

      if (window.isAndroidWebView() && window.AndroidCallback.copyToClipboard) {
        window.AndroidCallback.copyToClipboard(value)
        return
      }

      navigator.clipboard.writeText(value).then(() => {
        this.snackbar = true
        console.log('Async: Copying to clipboard was successful!')
      }, function(err) {
        console.error('Async: Could not copy text: ', err)
      })
    },
    async next() {
      if (this.fromType && this.fromType === 'register') {
        // this.$router.push({ name: 'Profile' })
        await this.goTo()
      }
    },
    async shareClick() {
      if (this.fromType && this.fromType === 'register') {
        setTimeout(() => {
          this.showNext = true
        }, 2 * 1000)
      }

      const shareData = {
        text: this.inviteInfo.share.share_title,
        url: this.inviteInfo.share.share_link_url
      }

      if (window.isAndroidWebView() && window.AndroidCallback.shareToFriends) {
        window.AndroidCallback.shareToFriends(shareData.text, shareData.url)
        return
      }

      if (window.isWebKit() && window.webkit.messageHandlers.shareToFriends) {
        window.webkit.messageHandlers.shareToFriends.postMessage(shareData)
        return
      }

      if (navigator.canShare(shareData)) {
        try {
          await navigator.share(shareData)
        } catch (err) {
          console.log('err: ' + err)
        }
      } else {
        console.log('error: not supported for current browser')
      }
    },
    toRedeem() {
      this.$router.push({ name: 'PointsRedeem' })
    },
    toHistory() {
      this.$router.push({ name: 'PointsHistory' })
    },
    async goTo() {
      if (window.isAndroidWebView()) {
        window.AndroidCallback.onSubscriptionCallback('success')
        return
      }

      if (window.isWebKit()) {
        window.webkit.messageHandlers.refreshUserSubInfo.postMessage('success')
        return
      }

      await this.$store.dispatch('user/getInfo')
      this.$router.push({ name: 'mychannel' })
    }
  }
}
</script>

<style lang="scss" scoped>
.referral-container{
    max-width: 370px!important;
}

.card-box{
    .reffer-rule-box{
      border-radius: 8px;
    }

    .desc-title-box{
        width:100%;
        text-align: center;
    }
    .desc-space{
        height: 20px;
    }
    .center-box{
        text-align: center;
    }
    .text-wrap{
        font-weight: 600 !important;
    }
    .footer-desc{
        margin: 20px;
        font-weight: 600;
    }
    .copy-link-list{
      .v-list-item__title{
        font-weight: bold;
      }

      img{
        width: 20px;
      }

      border-radius: 8px;
      padding: 0px !important;
      .v-list-item__action{
        margin: unset!important;
      }
    }
    .box-points{
       border-radius: 8px!important;
       border: none;
        img{
          padding-top: 4px;
          width: 24px;
        }
    }
    .points-space{
        width: 30px;
        padding-left: 65%
    }
    .point-value{
        // padding-top: 4px;
        width: 80%;
        font-size: 22px;
        color: #E040FB;
        border: none;
        float: left;
    }
    .referral-title{
      font-weight: bold;
    }
    .sub-btn{
      margin-top: 30px;
      font-weight: bold;
      letter-spacing: 0.08em;
      background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
      font-size: 800;
    }
}

.snackbar-copy{
  .v-alert {
      padding: unset!important;
  }
}

.buttong-justify-center{
  text-align: center;
}

.referral-code-box{
  text-align: center;
  font-weight: bold;
  div:nth-child(2){
    width: 120px;
    line-height: 40px;
    margin: 0 auto;
    background: #1E1E1E;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .v-icon{
    color: #E040FB;
  }
}
</style>
